import React, { useState } from 'react';
import PageLink from '../components/page-link';
import Svg from '../components/svg';
import Close from '../components/close';
import TextareaAutosize from 'react-autosize-textarea';
import axios from 'axios';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import CustomSelect from './select';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name_error: '',
      email_error: '',
      message_error: '',
      agree_error: '',
      name_focus: '',
      email_focus: '',
      message_focus: '',
      name: '',
      email: '',
      message: '',
      product: '',
      qcheck: '',
      success: false,
      // visable: false
    };
  }

  // toggleForm = (visible) => {
  //     this.setState({
  //         name_error: '',
  //         email_error: '',
  //         message_error: '',
  //         agree_error: '',
  //         name_focus: '',
  //         email_focus: '',
  //         message_focus: '',
  //         name: '',
  //         email: '',
  //         message: '',
  //         agree: '',
  //         success: false,
  //         visable: visible
  //     });
  // }

  // getValue = (childValue) => {
  //   this.setState({ product: childValue });
  // }

  submit = () => {
    if (typeof gtag != 'undefined' && process.env.ENV == 'prod') {
        gtag('event', 'screen_view', { 'app_name': 'Forma','screen_name': 'Kontaktai' });
    }

    let valid = true;
    const that = this;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid_email = re.test(String(this.state.email).toLowerCase());
    if (!this.state.name) {
      valid = false;
      this.setState({ name_error: 'error' });
    }
    if (!valid_email) {
      valid = false;
      this.setState({ email_error: 'error' });
    }
    if (!this.state.message) {
      valid = false;
      this.setState({ message_error: 'error' });
    }
    if (valid) {
      axios
        .post(process.env.GATSBY_CONTACT_ENDPOINT, {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
          product: this.state.product,
          qcheck: this.state.qcheck,
        })
        .then(function (response) {
          if (response.data.success) {
            that.setState({ success: true });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  render() {
    // if (!this.state.visable) return null;

    const products = this.props.pages.filter(
      (page) => page[this.props.lang].template === 'products-parent'
    )[0][this.props.lang].items;
    const productNames = products.map((item) => ({
      label: item.title,
      value: item.title,
    }));

    let form = null;
    let message = null;
    if (this.state.success) {
      message = (
        <div
          className='success-message'
          id='contact-form-success'
          dangerouslySetInnerHTML={{
            __html: this.props.data.contact_form_success,
          }}
        />
      );
    } else {
      form = (
        <div className='form'>
          {/* <div
            className='layer-label'
            dangerouslySetInnerHTML={{
              __html: this.props.data.contact_form_section,
            }}
          /> */}
          <div
            className={`input ${this.state.name_error} ${this.state.name_focus}`}
          >
            <label>{this.props.data.contact_form_name}</label>
            <input
              type='text'
              onChange={(event) => this.setState({ name: event.target.value })}
              onFocus={() =>
                this.setState({ name_error: '', name_focus: 'focus' })
              }
              onBlur={() =>
                this.setState({ name_focus: this.state.name ? 'focus' : '' })
              }
            />
            <div
              className='error-message'
              dangerouslySetInnerHTML={{
                __html: this.props.data.contact_form_name_error,
              }}
            />
          </div>
          <div
            className={`input ${this.state.email_error} ${this.state.email_focus}`}
          >
            <label>{this.props.data.contact_form_email}</label>
            <input
              type='text'
              onChange={(event) => this.setState({ email: event.target.value })}
              onFocus={() =>
                this.setState({ email_error: '', email_focus: 'focus' })
              }
              onBlur={() =>
                this.setState({ email_focus: this.state.email ? 'focus' : '' })
              }
            />
            <div
              className='error-message'
              dangerouslySetInnerHTML={{
                __html: this.props.data.contact_form_email_error,
              }}
            />
          </div>
          <CustomSelect
            options={productNames}
            texts={this.props.data}
            sendValue={(value) => this.setState({ product: value?.value })}
          />
          <div
            className={`input ${this.state.message_error} ${this.state.message_focus}`}
          >
            <label>{this.props.data.contact_form_message}</label>
            <TextareaAutosize
              type='text'
              onChange={(event) =>
                this.setState({ message: event.target.value })
              }
              onFocus={() =>
                this.setState({ message_error: '', message_focus: 'focus' })
              }
              onBlur={() =>
                this.setState({
                  message_focus: this.state.message ? 'focus' : '',
                })
              }
            />
            <div
              className='error-message'
              dangerouslySetInnerHTML={{
                __html: this.props.data.contact_form_message_error,
              }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '-50000px',
              left: '-50000px',
            }}
          >
            <input type='text' name='qcheck' id='qcheck' onChange={(event) =>
                this.setState({ qcheck: event.target.value })
              } />
          </div>

          <div className='form-bottom'>
            <div className={`form-small-agree`}>
              <div>{this.props.data.product_contact_form_agree}</div>{' '}
              <PageLink
                lang={this.props.lang}
                pages={this.props.pages}
                to='privacy-policy'
              >
                {this.props.data.product_contact_form_agree_link}
              </PageLink>
            </div>

            <button className='btn' onClick={this.submit}>
              {this.props.data.contact_form_btn}
            </button>
          </div>
        </div>
      );
    }
    return (
      // <div className="popup">
      //     <Close text={this.props.data.close} onClick={() => this.toggleForm(false)} />
      //     <div className="small-wrap">
      //         <div className="h2" dangerouslySetInnerHTML={{__html: this.props.data.contact_form_title }} />
      //         {form}
      //         {message}
      //     </div>
      // </div>
      <div className='form-wrap'>
        <div
          className='h3'
          dangerouslySetInnerHTML={{
            __html: this.props.data.contact_form_title,
          }}
        />
        {form}
        {message}
      </div>
    );
  }
}

export default ContactForm;
